/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname, hash } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = ["/"]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1 && hash === "") {
    window.scrollTo(0, 0)
    return false
  }

  return true
}
